import React from 'react';

import { PersonalDataDTO } from '~/pages/privacy-policy/types';
import styles from './../../PrivacyPolicy.module.scss';

export interface Props {
  data: PersonalDataDTO;
}

const PersonalData: React.FC<Props> = ({ data: { title, description } }) => {
  const renderItem = (item: string, index: number) => (
    <li className={styles.item} key={index}>
      {item}
    </li>
  );

  const renderList = (items: string[]) => (
    <ul className={styles.list}>{items.map(renderItem)}</ul>
  );

  const renderDescription = () =>
    description.map(({ text, items }, index) => (
      <React.Fragment key={index}>
        <p className={styles.paragraph}>{text}</p>
        {renderList(items)}
      </React.Fragment>
    ));

  return (
    <>
      <h2 className={styles.subtitle}>{title}</h2>
      {renderDescription()}
    </>
  );
};

export default PersonalData;
