import React from 'react';

import { ContactSectionDTO } from '~/pages/privacy-policy/types';

import styles from './../../PrivacyPolicy.module.scss';

export interface Props {
  data: ContactSectionDTO;
}

const Contact: React.FC<Props> = ({ data: { title, description } }) => (
  <>
    <h2 className={styles.subtitle}>{title}</h2>
    <p
      className={styles.paragraph}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </>
);

export default Contact;
