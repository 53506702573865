import React from 'react';
import Layout from '~/components/Layout';
import PersonalData from '~/pages/privacy-policy/components/PersonalData';
import Contact from '~/pages/privacy-policy/components/Contact';
import privacyPolicy from '~/data/privacy-policy';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  const {
    title,
    subtitle,
    personalDataSection,
    contactSection,
    fullPrivacyPolicy,
    fullPrivacyPolicyLabel,
  } = privacyPolicy;

  return (
    <Layout invertNavigation>
      <section className={styles.root}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.paragraph}>{subtitle}</p>
        <PersonalData data={personalDataSection} />
        <Contact data={contactSection} />
        <a
          className={styles.btn}
          href={fullPrivacyPolicy}
          title={fullPrivacyPolicyLabel}
          role='button'
          target='_blank'
          rel='noopener noreferrer'
        >
          {fullPrivacyPolicyLabel}
        </a>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
