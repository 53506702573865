import { PrivacyPolicyPageDTO } from '~/pages/privacy-policy/types';

const privacyPolicy: PrivacyPolicyPageDTO = {
  title: 'Privacy Policy',
  subtitle: 'This Application collects some Personal Data from its Users.',
  personalDataSection: {
    title:
      'Personal Data collected for the following purposes and using the following services',
    description: [
      {
        text: 'Analytics:',
        items: [
          'Google Analytics, Segment and Facebook Ads conversion tracking',
          'Personal Data: Cookies, Usage Data and various types of Data as specified in the privacy policy of the service',
        ],
      },
    ],
  },
  contactSection: {
    title: 'Contact information',
    description:
      "Owner and Data Controller Swing Development Inc. 339 15th St # 202 Oakland, CA 94612 <br> Owner contact email: <a href='mailto:hello@swing.dev'>hello@swing.dev</a>",
  },
  fullPrivacyPolicy: 'https://www.iubenda.com/privacy-policy/72268129/legal',
  fullPrivacyPolicyLabel: 'Show the complete Privacy Policy',
};

export default privacyPolicy;
